export const responseHandler = async (res) => {
  let response;
  if (
    res.status !== 204 &&
    res.headers.get('content-type') &&
    res.headers.get('content-type').includes('application/json')
  ) {
    response = await res.json();
  } else {
    response = await res.text();
  }
  if (!res.ok) {
    throw response;
  }
  return response;
};

export const test = () => true;
