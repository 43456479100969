import { responseHandler } from './responseHandler';
import { reFetchAuthToken } from './user.service';
import { getAuth } from './utils';

const request = async (url, { method, headers, payload, withAuth = true }) => {
  const auth = getAuth();

  return fetch(url, {
    method,
    headers: {
      ...(withAuth && auth),
      ...headers,
    },
    ...(payload && { body: JSON.stringify(payload) }),
  })
    .then(responseHandler)
    .catch(async (err) => {
      if (err === 'Jwt is expired') {
        await reFetchAuthToken();
        return request(url, { method, headers, payload });
      } else {
        throw err;
      }
    });
};

export default request;
