import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = (props) =>
  makeStyles(() => ({
    root: { color: props.color },
  }));

const Loading = ({ size = 23, color = 'white' }) => {
  const classes = useStyles({ color })();
  return (
    <CircularProgress className={classes.root} size={size}></CircularProgress>
  );
};

Loading.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Loading;
