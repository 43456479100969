import React from 'react';
import styled from 'styled-components';
import { getURLSearchParamByKey } from 'src/utils';
import Layout from 'components/layout';

const Wrapper = styled.div`
  height: 100%;
  background-color: #fff;
  padding-bottom: 30px;
`;

const IFrameFullScreen = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 30px);
  border: none;
  background-color: white;
  z-index: 9999;
`;

export default function PinMedBookingIframe({ user, lineProfilePhone }) {
  const openUrl = getURLSearchParamByKey('open');

  let phone = user.Phone || lineProfilePhone || '';

  const userInfo = {
    name: user.name || '',
    gender: user.gender || 'M',
    birth: user.birth || '',
    phone: phone,
    idType: user.idType,
    idNum: user.idNum,
    openFromLiff: true,
  };

  const params = !!phone && new URLSearchParams(userInfo).toString();

  console.log({ openUrl, userInfo, params });

  return openUrl ? (
    <Layout title="PinMed Booking">
      <Wrapper>
        <IFrameFullScreen src={`${openUrl}${params ? `?${params}` : ''}`} />
      </Wrapper>
    </Layout>
  ) : null;
}
