import React, { useEffect, useState } from 'react';
import { setupLiff } from 'src/lib/liff';
import { getUserInfo, fetchAndSaveAuthToken } from 'services/user.service';
import { setAuth } from 'src/services/utils';
import PinMedBookingIframe from 'src/components/PinMedBookingIframe';
import { t } from 'src/i18n/config';
import styled from 'styled-components';
import Loading from 'src/components/1TalkDesign/Loading';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export default function PinMedBooking() {
  const [lineProfilePhone, setLineProfilePhone] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        const { userId, liffId, lineProfilePhone } = res;
        setLineProfilePhone(lineProfilePhone);
        if (userId && liffId) {
          const token = await fetchAndSaveAuthToken({ userId, liffId });
          setAuth({ token, liffId, userId });
          const user = await getUserInfo().catch((err) => {
            console.log('取得使用者資訊失敗', err.message);
            return {};
          });
          if (
            user.patients &&
            user.patients.length !== 0 &&
            user.patients.find((u) => u.isSelf)
          ) {
            const self = user.patients.find((u) => u.isSelf);
            setUser({ ...self, ...user });
          } else {
            setUser(user);
          }
          return;
        }
        alert(
          `${t('userNotFound')}${userId}, ${liffId} ${window.location.href}`
        );
      })
      .catch(async (err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <LoadingWrapper>
      <Loading color="rgb(0, 109, 177)" size={50} />
    </LoadingWrapper>
  ) : (
    <PinMedBookingIframe user={user} lineProfilePhone={lineProfilePhone} />
  );
}
